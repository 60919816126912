import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Header.scss';
import Donate from './Donate';

const Header = () => {
  return (
    <>
      <div className="total">TOTAL COLLECTED: $211,829.96</div>

      <section id="topBanner">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1 className="mainTitle">We stand with Ukraine</h1>
              <p className="mainDescription">
                Our mission: stand with Ukraine during war, supporting citizens
                in their brave fight for lives, freedom, and human rights. We
                extend our commitment globally, striving to offer aid and relief
                where it’s needed most.
                </p>
		<p className="mainDescription">
		! IMPORTANT ! Starting in 2025, we partnered with the non-profit
    organization Ukraine Aid Fund. Please use the button below for all 
    your charitable donations using the UAF platform.
    </p>
                 <div className="mainDiv">
                <Donate />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Header;
