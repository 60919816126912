import React from 'react';
import './DonateButton.scss';
import useAnalyticsEventTracker from './../useAnalyticsEventTracker';


const Donate = () => {
  const gaEventTracker = useAnalyticsEventTracker('DonateButton');
  const hostedButtonId = "6KZD4YP6NB4XC";
  const handleSubmit = (event) => {
    event.preventDefault();
    gaEventTracker('Donate')
    window.location.href = `https://ukraineaidfund.org/donate?type=4`;
  };
  return (
    <form onSubmit={handleSubmit} target="_top">
      <button type="submit" className="donate-button">
        DONATE
      </button>
    </form>
  );
};

export default Donate;
